form[name="lernziel"] {

  textarea, input[type="text"] {
    display: block;
    width: 100%;
    resize: none;
  }

  > div {
    margin-top: 0;
  }
}

.tabs-container {
  display: table;
  width: 98%;

  .tabs-schieberegler, .tabs-content, .tabs-title {
    display: table-cell;
    transition: .7s ease-out;
    overflow: hidden;
  }

  .tabs-schieberegler {
    width: 0%;
    border-right: 1px solid #d2d2d2;

    &:last-child {
      border: none;
    }

    &.active {
      width: 100%;

      .tabs-title {
        color: black;
      }

      form {
        max-height: 100%;
      }
    }

    .tabs-content {
      max-width: 0;
      width: 100%;
    }

    form {
      max-height: 0;
      transition: .7s ease-out;
      overflow: hidden;
    }

    .tabs-title {
      padding: 0 15px;
      color: #c4c4c4;
      font-size: 16px;
      cursor: pointer;
      text-align: center;
    }
  }
}

.richtextbox {
  background-color: #F5F5E0;
  border-radius: 3px;
  font-size: medium;
  margin-top: 2px;
  padding: 5px;
}

.bearbeitenHintergrund {
  padding-left: 32px;
  position: relative;
}

.bearbeitenHintergrund > div {
  padding-right: 30px;
}

.bearbeitenHintergrund:before {
  content: "\f040"; /* Stift */
  position: absolute;
  font-family: 'FontAwesome';
  top: -10px;
  right: -5px;
  font-size: 3.2em;
  color: darkgray;

}

.lernzielVerbSelect {
  color: darkblue;
  font-weight: bold;
  padding-left: 1px;
  padding-right: 1px;
  background-color: lightblue;
}
