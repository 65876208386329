.select2-container {
  width: 100%;
  display: block;
}

.select2-search {
  border: 1px solid #aaa;
  border-bottom: none;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0;
  margin: 10px 0 0 0;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: none;
  border-bottom: 1px solid #aaa;
}

.select2-container--default .select2-selection--multiple {
  border: none;
  border-bottom: 1px solid #aaa;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: block;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  margin: 0;
  padding: 0;
  width: 100%;

  li {
    width: 100%;
    float: none;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.select2-container .select2-search--inline .select2-search__field {
  margin: 0;
  padding: 8px 5px;
  width: 100% !important;
}

li.select2-search.select2-search--inline:before {
  content: attr(data-input-label);
  color: #aaa;
  padding-left: 10px;
}