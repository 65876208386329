.verb-type-selection {

  margin-bottom: 28px;

  .suggestions {
    margin: 5px 0 14px 0;

    span a {
      margin-right: 6px;
      text-decoration: underline;
      color: darkblue;
      cursor: pointer;
    }
  }

  .current-value  {
    //font-weight: bold;
  }

  .token-type {
    border: none;
    background-color: transparent;

    .token-type-header {
      padding: 0;
    }

    .token-type-details {
      border: none;
      padding: 0 0 0 30px;

      .token-type-action {
        cursor: pointer;
        width: 100%;
        text-decoration: underline;
        margin-top: 7px;

        &.selected {
          font-weight: bold;
        }
      }
    }
  }
}