$neutralColor: lightgray;
$oldNewSeperator: rgb(160, 160, 160);
$activeColor: $brand-primary;
$stepNumberSize: 38px;

.review {
  margin: 0 8px;

  .status {
    margin: 20px 0;
    display: table;
    table-layout: fixed;
    width: 100%;

    .step {
      display: table-cell;
      text-align: center;
      color: $neutralColor;
      width: 100%;
      position: relative;

      &:not(:last-child)::after, &:not(:first-child)::before {
        content: "";
        border-top: 3px solid $neutralColor;
        position: absolute;
        top: 19px;
        right: 0;
        width: calc(50% - #{$stepNumberSize});
      }

      &::before {
        left: 0;
        right: auto;
      }

      .number {
        height: $stepNumberSize;
        width: $stepNumberSize;
        border-radius: $stepNumberSize / 2;
        border: 3px solid $neutralColor;
        margin: auto;
        line-height: $stepNumberSize - 4px;
        font-size: 17px;
        margin-bottom: 7px;
      }

      .label {
        font-size: 12px;
      }

      &.active, &.done {
        &::before, .number, .label {
          border-color: $activeColor;
          color: $activeColor;
        }
      }

      &.active {
        .number {
          background-color: $activeColor;
          border-color: $activeColor;
          color: white;
        }
      }

      &.done::after {
        border-color: $activeColor;
      }
    }
  }

  .lve-heading {
    h2 {
      width: 100%;
      background-color: white;
      z-index: 2;
      border-bottom: 1px solid black;
      margin: 0;
      padding: 7px 0;
    }

    &.fixed {
      h2 {
        position: fixed;
        top: 0;
        right: 0;
        box-shadow: 3px 3px 6px rgba($color: #bebebe, $alpha: 0.5);
        border: none;
        padding: 7px 13px;
        text-align: center;
      }
    }
  }

  > div > input {
    width: 100%;
    margin-bottom: 10px;
  }

  .item {
    padding: 9px;
    margin-bottom: 8px;

    border: solid 1px lightgrey;
    border-radius: 3px;
    box-shadow: 3px 3px 5px rgba($color: #bebebe, $alpha: 0.5);

    .heading {
      margin: 15px 0;
    }

    .actions {
      margin-top: 8px;
      display: table;
      width: 100%;

      label {
        display: table-cell;
      }
    }

    .changed-lo {
      display: none;
    }

    > div > h4 {
      color: $oldNewSeperator;
      font-size: 11px;
      margin: 0;
    }

    .no-entries {
      font-style: italic;
    }

    div.old {
      border-bottom: 1px solid $neutralColor;
      padding-bottom: 4px;
      margin-bottom: 8px;

      .details {
        display: table;
        margin-bottom: 9px;

        &.ui-tabs {
          .ui-tabs-nav {
            display: table-cell;
            padding: 0;
            vertical-align: top;

            li {
              float: none;
              text-align: right;
              margin: 0;
              padding: 3px 0;

              &.ui-tabs-active {
                border: none;
              }
            }

            .ui-tabs-anchor {
              padding: 0 12px 0 0;
              font-weight: normal;
              float: none;
            }
          }

          .ui-tabs-panel {
            background: white;
            padding: 5px;
            border-radius: 0;
            display: table-cell;
          }
        }

        .comment {
          &:not(:last-child) {
            margin-bottom: 5px;
          }

          span {
            display: block;
            padding: 0 0 3px 0;

            &.label {
              font-style: italic;
            }
          }

        }

        p {
          margin: 0;
        }
      }

      .details-1 {
        &.ui-tabs {
          .ui-tabs-nav {
            border-right: 1px solid $neutralColor;

            li {
              border-bottom: 1px solid transparent;

              &.ui-tabs-active {
                border-bottom: 1px solid $neutralColor;
              }
            }
          }
        }
      }

      .details-2 {
        &.ui-tabs {
          .ui-tabs-nav {
            padding: 0;

            li {
              margin: 0;
              padding: 3px 0;

              &.ui-tabs-active {
                border: none;
              }
            }

            .ui-tabs-anchor {
              padding: 0 12px 0 0;
              font-weight: normal;
            }
          }

          .ui-tabs-panel {
            background: white;
            padding: 5px 0;
            border-radius: 0;

            .comment {
              &:not(:last-child) {
                margin-bottom: 5px;
              }

              span {
                display: block;
                padding: 0 0 3px 0;

                &.label {
                  font-style: italic;
                }
              }

            }

            p {
              margin: 0;
            }
          }
        }
      }
    }

    div.new {
      .notice {
        width: 100%;
        margin-top: 11px;

        textarea {
          width: 100%;
        }
      }

      .submit {
        text-align: right;
      }

      .btn {
        display: none;
      }
    }
  }
}