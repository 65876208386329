// customize some Bootstrap variables
// the ~ allows you to reference things in node_modules
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$brand-primary: darken(#428bca, 20%);

@import 'jqueryui-customization';
@import 'select2-tweaks';
@import 'token-type';
@import 'lernziel';
@import 'catalogue';
@import 'verb-selection-type';
@import 'review';

* {
  box-sizing: border-box;
}

label {
  font-weight: bold;
}

.col-form-label {
  font-weight: bold;
  margin-bottom: 0;
  padding-bottom: 0;
}

div + p.no-entries {
  display: none;
}

.rich-textbox {
  border: 1px solid black;
  padding: 5px 3px;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.inputSmall {
  width: 25% !important;
}

.ui-tabs .ui-tabs-panel {
  padding: 0;
}

.card-body {
  padding-left: 0.6rem !important;
  padding-right: 0.6rem !important;
  padding-top: 0.6rem !important;
  padding-bottom: 0.5rem !important;
}

.form-check-input {
  margin-top: 0.1rem !important;
  margin-left: -1.4rem;
}

.action {
  font-weight: bold;
  font-size: large;
  position: absolute;
}

/** Fix Bootstrap Grid for IE */
.row {
  margin-left: -0px !important;
}

.ui-tabs .ui-tabs-panel {
  margin-left: -15px !important;
}

// customize some Bootstrap variables
$brand-primary: darken(#428bca, 20%);
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";

// the ~ allows you to reference things in node_modules

@import '../../../../../LLPCommon/Infrastructure/UI_Web/assets/css/token-type';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/alert';
//@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/badge';

//add fontawesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";