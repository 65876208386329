$llp-item-bg: #005EBB;

.ui-tabs .ui-tabs-nav {
  overflow: auto;
}

.ui-corner-all {
  border-radius: 0;
}

.ui-tabs {
  border: none;
  background: none;
  background-color: #fff;
  padding: 0;

  .ui-widget-header {
    background: none;
    background-color: #fff;
    border: none;

    a:focus {
      outline: none !important;
    }

    .ui-state-default {
      border: none;
      background: none;
    }

    .ui-tabs-active {
      border-bottom: solid 4px $llp-item-bg;
    }
  }

  .ui-tabs-panel {
    height: auto !important;
  }
}