@mixin catalogue-entry {
  position: relative;
  margin-bottom: 8px;
  background-color: #f3f3ff;
  padding: 7px 25px 7px 7px;

  &.mapped {
    background-color: #f3f3f3;
    cursor: auto;
  }
}

.catalogue-tree-view {
  .catalogue-view-cat-item {
    .title {
      padding: 0 16px 0 0;
    }

    &.token-type {
      border: none;
      border-radius: 0;
      background-color: transparent;
      margin: 0;
    }

    .token-type-details {
      border-top: none;
      padding: 6px 0 0 25px;
    }

    &.is-leaf {
      @include catalogue-entry;
    }
  }

  > .catalogue-view-cat-item {
    padding-top: 10px;
  }
}

span.action {
  display: inline-block;
  cursor: pointer;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  :hover {
    color: red;
  }
}

.catalogue-browser {
  margin-left: 10px;

  ul.breadcrumbs {
    font-size: 14px;
    margin: 0;
    padding: 10px;

    li {
      display: inline-block;
      color: #55a;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 550px;
      cursor: pointer;
      white-space: nowrap;

      &:before {
        content: '>';
        margin: 0 6px;
      }

      &:first-child:before {
        content: none;
      }
    }
  }

  .catalogue-browser-entries {
    div {
      @include catalogue-entry;
    }
  }
}

.catalogue-search {
  input {
    width: 100%;
  }

  .catalogue-search-results {
    span.match {
      background-color: yellow;
    }
  }
}