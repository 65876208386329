.token-type {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin: 7px 0;

  a:hover {
    color: #000;
  }

  .token-type-header {
    padding: 3px 10px 3px 7px;

    > a.token-type-action {
      text-decoration: none;
      color: #999;
      float: right;
      cursor: pointer;
      font-weight: bold;
    }

    > span.token-type-opener {
      cursor: pointer;
      display: inline-block;

      &::before {
        color: #999;
        content: '▶︎';
        margin-right: 3px;
      }
    }
  }

  &.open > .token-type-header > span.token-type-opener::before {
    content: '▼';
  }

  .token-type-details {
    display: none;
    border-top: 1px solid #aaa;
    padding: 7px 7px;
  }

  &.open > .token-type-details {
    display: block;
  }
}